import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Button, Container, Grid, Stack, TextField, Typography, CardContent, Card } from '@mui/material';

import { styled } from '@mui/system';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import useSettings from '../../hooks/useSettings';

import { postPollQuestion, getPollQuestion, editPollQuestion } from '../../services/poll';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export default function PollQuestions() {
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newOption1, setNewOption1] = useState('');
  const [newOption2, setNewOption2] = useState('');
  const [newOption3, setNewOption3] = useState('');
  const [newOption4, setNewOption4] = useState('');
  const [editingQuestion, setEditingQuestion] = useState(null);


  const fetchData = async () => {
    try {
      const data = await getPollQuestion();
      setQuestions(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleAddQuestion = async () => {
    if (!newQuestion || !newOption1 || !newOption2 || !newOption3 || !newOption4) {
      enqueueSnackbar('All fields are required', { variant: 'error' });
      return;
    }
    const newQuestionObject = {
      question: newQuestion,
      option1: newOption1,
      option2: newOption2,
      option3: newOption3,
      option4: newOption4,
    };

    try {
      const response = await postPollQuestion(newQuestionObject);

      enqueueSnackbar(response.message, { variant: 'success' });

      // setQuestions([...questions, response]);
      try {
        const data = await getPollQuestion();
        setQuestions(data.data);
      } catch (error) {
        console.log(error.message);
        enqueueSnackbar(error.message, { variant: 'error' });
      }

      setNewQuestion('');
      setNewOption1('');
      setNewOption2('');
      setNewOption3('');
      setNewOption4('');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleEditQuestion = (question) => {
    setEditingQuestion(question);

    setNewQuestion(question.question);
    setNewOption1(question.option1);
    setNewOption2(question.option2);
    setNewOption3(question.option3);
    setNewOption4(question.option4);
  };

  const handleUpdateQuestion = async () => {
    try {
      if (!editingQuestion || !newQuestion || !newOption1 || !newOption2 || !newOption3 || !newOption4) {
        enqueueSnackbar('All fields are required', { variant: 'error' });
        return;
      }
      const updatedQuestionObject = {
        id: editingQuestion.id,
        question: newQuestion,
        option1: newOption1,
        option2: newOption2,
        option3: newOption3,
        option4: newOption4,
      };

      const response = await editPollQuestion(updatedQuestionObject);

      const updatedQuestions = questions.map((q) => (q.id === response.id ? response : q));
      setQuestions(updatedQuestions);
      enqueueSnackbar('Question updated successfully', { variant: 'success' });
      setEditingQuestion(null);
      setNewQuestion('');
      setNewOption1('');
      setNewOption2('');
      setNewOption3('');
      setNewOption4('');
      fetchData();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Page title="PollQuestion: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Poll Questions"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Poll Questions' }]}
        />

        <Stack spacing={3}>
          <StyledCard>
            <Typography variant="h4" mb={2}>
            
              {editingQuestion ? 'Edit Question' : 'Add Question for Poll'}
            </Typography>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  label="Question"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={6} sm={12}>
                <TextField
                  fullWidth
                  label="Option"
                  value={newOption1}
                  onChange={(e) => setNewOption1(e.target.value)}
                  variant="outlined"
                />
              </Grid>{' '}
              <Grid item lg={6} sm={12}>
                <TextField
                  fullWidth
                  label="Option"
                  value={newOption2}
                  onChange={(e) => setNewOption2(e.target.value)}
                  variant="outlined"
                />
              </Grid>{' '}
              <Grid item lg={6} sm={12}>
                <TextField
                  fullWidth
                  label="Option"
                  value={newOption3}
                  onChange={(e) => setNewOption3(e.target.value)}
                  variant="outlined"
                />
              </Grid>{' '}
              <Grid item lg={6} sm={12}>
                <TextField
                  fullWidth
                  label="Option"
                  value={newOption4}
                  onChange={(e) => setNewOption4(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={2} sm={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ p: 2 }}
                  onClick={editingQuestion ? handleUpdateQuestion : handleAddQuestion}
                >
                  {editingQuestion ? 'Update Question' : 'Add Question'}
                </Button>
              </Grid>
            </Grid>
          </StyledCard>

          <Stack spacing={3}>
            <Grid container spacing={2}>
              {questions?.map((question, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <StyledCard variant="outlined" sx={{ minHeight: 250, p: 0 }}>
                    <CardContent>
                      <Typography variant="h5" mb={2}>
                        {question.question}
                      </Typography>
                      <Stack direction="row" spacing={6}>
                        <Stack spacing={1} justifyContent="flex-start" width="50%">
                          <Typography variant="p" textAlign="left">
                            A : {question.option1}
                          </Typography>
                          <Typography variant="p" textAlign="left">
                            B : {question.option2}
                          </Typography>
                        </Stack>
                        <Stack spacing={1} justifyContent="flex-start" width="50%">
                          <Typography variant="p" textAlign="left">
                            C : {question.option3}
                          </Typography>
                          <Typography variant="p" textAlign="left">
                            D : {question.option4}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Button
                        variant="contained"
                        onClick={() => handleEditQuestion(question)}
                        style={{ marginTop: '20px', width: 50 }}
                      >
                        Edit
                      </Button>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
}
