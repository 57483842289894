import axios from '../utils/axios';
import { baseEndpoints } from './config';

const getCityList = async () => {
    const { data } = await axios.get(`${baseEndpoints.address}/city_list`
    )
    return data;
};
const getCityListByStateId = async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/city_list_by_state_id`,body
    )
    return data;
};

const createCity = async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/city_add`,
        body
    );
    return data;
};


const updateCity = async (body) => {
    const { data } = await axios.put(`${baseEndpoints.address}/city_update`,
        body
    );
    return data;
};



export const cityService = {
    getCityList,
    createCity,
    updateCity,
    getCityListByStateId
}