import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, MenuItem, Typography } from '@mui/material';
import { validate } from 'numeral';
// components
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import { TableMoreMenu } from '../../components/table';
import { changePollStatus } from '../../services/poll';

// ----------------------------------------------------------------------

PollsPublishTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  fetchPublishList: PropTypes.func,
};

export default function PollsPublishTableRow({ row, onViewRow, onEditRow, fetchPublishList }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { countryName, stateName, question, cityName, apartmentName, neighbourhoodName, validUpto, publishStatus } = row;

  let color;
  switch (publishStatus) {
    case 'unpublished':
      color = '';
      break;
    case 'published':
      color = 'info.main';
      break;
    case 'expired':
      color = 'error.main';
      break;
    default:
      color = 'primary.main';
      break;
  }

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleChangeStatus = async (id, status) => {
    const body = {
      id,
      publishStatus: status,
    };
    try {
      const response = await changePollStatus(body);
      console.log(response);
      enqueueSnackbar(response.message);
      fetchPublishList();
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <TableRow hover>
      <TableCell align="left">{question}</TableCell>
      <TableCell align="left">{countryName || '-'}</TableCell>
      <TableCell align="left">{stateName || '-'}</TableCell>
      <TableCell align="left">{cityName}</TableCell>
      <TableCell align="left">{neighbourhoodName}</TableCell>
      <TableCell align="left">{apartmentName}</TableCell>

      <TableCell align="center">{validUpto}</TableCell>
      <TableCell align="left">
        <Typography color={color} sx={{ textTransform: 'capitalize' }}>
          {publishStatus}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {publishStatus === 'unpublished' && (
                <MenuItem
                  onClick={() => {
                    onEditRow(row);
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
              )}
              {publishStatus === 'unpublished' && (
                <MenuItem
                  sx={{ color: 'info.main' }}
                  onClick={() => {
                    handleChangeStatus(row.id, 'published');

                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Publish
                </MenuItem>
              )}
              {publishStatus === 'published' && (
                <MenuItem
                  onClick={() => {
                    handleChangeStatus(row.id, 'expired');
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Expire
                </MenuItem>
              )}
              {publishStatus !== 'unpublished' && (
                <MenuItem
                  onClick={() => {
                    onViewRow();
                    handleCloseMenu();
                  }}
                  // sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:eye-fill'} />
                  View Result
                </MenuItem>
              )}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
