import axios from '../utils/axios';
import { baseEndpoints } from './config';

const getNeighbourhoodList = async () => {
    const { data } = await axios.get(`${baseEndpoints.address}/neighbourhood_list`
    )
    return data;
};
const getNeighbourhoodListByCity = async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/neighbourhood_listcity`,body
    )
    const activeCities = data?.data?.filter(city=>city.status === 1)
    console.log(data)
    console.log(activeCities)
    return activeCities;
};

const createNeighbourhood = async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/neighbourhood_add`,
        body
    );
    return data;
};
const updateNeighbourhood = async (body) => {
    const { data } = await axios.put(`${baseEndpoints.address}/neighbourhood_update`,
        body
    );
    return data;
};


export const neighbourhoodService = {
    getNeighbourhoodList,
    createNeighbourhood,
    updateNeighbourhood,
    getNeighbourhoodListByCity
}