import axios from '../utils/axios';
import { baseEndpoints } from './config';

const getApartmentsList = async () => {
    const { data } = await axios.get(`${baseEndpoints.address}/apartment_list`
    )
    return data;
};

const createApartment= async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/apartment_add`,
        body
    );
    return data;
};
const updateApartment = async (body) => {
    const { data } = await axios.put(`${baseEndpoints.address}/apartment_update`,
        body
    );
    return data;
};
const getApartmentsByNeighbourhood = async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/apartment_listneighbourhood`,
        body
    );
    return data;
};


export const apartmentService = {
    getApartmentsList,
    createApartment,
    updateApartment,
    getApartmentsByNeighbourhood
}