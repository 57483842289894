// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';


// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  city: getIcon('city'),
  placeholder: getIcon('placeholder'),
  apartments: getIcon('apartment'),
  product: getIcon('product'),
  poll: getIcon('poll'),
  feeds: getIcon('feeds'),
  settings: getIcon('settings'),
};



const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
        
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard ,roles:['Superadmin','Admin','Associate','Delivery Partner']}],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // City
      {
        title: 'cities',
        path: PATH_DASHBOARD.city.list,
        icon: ICONS.city,
        roles:['Superadmin','Admin',]
      },

      // Neighbourhoods
      {
        title: 'Neighbourhoods',
        path: PATH_DASHBOARD.neighbourhood.list,
        icon: ICONS.placeholder,
        roles:['Superadmin','Admin']

      },

      // Apartments
      {
        title: 'apartments',
        path: PATH_DASHBOARD.apartment.list,
        icon: ICONS.apartments,
        roles:['Superadmin','Admin']

      },

      // Category
      {
        title: 'categories',
        path: PATH_DASHBOARD.category.list,
        icon: ICONS.menuItem,
        roles:['Superadmin','Admin','Associate']

      },

      // Products
      {
        title: 'products',
        path: PATH_DASHBOARD.product.list,
        icon: ICONS.product,
        roles:['Superadmin','Admin','Associate']

      },

      // Feed
      {
        title: 'Feeds',
        path: PATH_DASHBOARD.feed.feed,
        icon: ICONS.feeds,
        roles:['Superadmin','Admin',]

      },
      // Settings
      {
        title: 'Taxes',
        path: PATH_DASHBOARD.settings.list,
        icon: ICONS.settings,
        roles:['Superadmin','Admin',]

      },

      // USER
      {
        title: 'users',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles:['Superadmin','Admin',],

        children: [
          { title: 'admins', path: PATH_DASHBOARD.user.adminList },
          { title: 'Customers', path: PATH_DASHBOARD.user.consumers },
        ],
      },
      // poll
      {
        title: 'polls',
        path: PATH_DASHBOARD.poll.root,
        icon: ICONS.poll,
        roles:['Superadmin','Admin','Associate'],

        children: [
          { title: 'poll questions', path: PATH_DASHBOARD.poll.pollsQuestions },
          { title: 'poll publish', path: PATH_DASHBOARD.poll.pollsPublish },
         
        ],
      },

      // Orders
      {
        title: 'Orders',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.cart,
        children: [
          { title: 'Order Plans', path: PATH_DASHBOARD.orders.orderPlan,roles:['Superadmin','Admin','Associate'] },
          { title: 'Running Orders', path: PATH_DASHBOARD.orders.sales,roles:['Superadmin','Admin','Associate'] },
          { title: 'Admin Purchase', path: PATH_DASHBOARD.orders.adminPurchase,roles:['Superadmin','Admin','Associate',] },
          { title: 'Order Delivery', path: PATH_DASHBOARD.orders.delivery,roles:['Superadmin','Admin','Associate','Delivery Partner']  },
          { title: 'Customer Orders', path: PATH_DASHBOARD.orders.customerOrders,roles:['Superadmin','Admin','Associate']  },

        ],
        roles:['Superadmin','Admin','Associate','Delivery Partner']

      },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },
];

export default navConfig;
