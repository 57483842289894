export const baseEndpoints = {
    auth: `/auth`,
    address:`/admin/address`,
    admin:`/admin`,
    category:`/admin/category`,
    product:`/admin/product`,
    order:`/admin/order`,




}
export const config = {
    headers: {
        // Authorization: process.env.REACT_APP_API_TOKEN,
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        'platform': process.env.REACT_APP_PLAFORM
    }
};