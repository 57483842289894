import { createSlice } from "@reduxjs/toolkit";

const adminslice = createSlice({
    name:'admins',
    initialState:{
        adminList : []
},
    reducers:{
        setAdmins:(state,action)=>{
            state.adminList = action.payload
        }
    }
})

export const {setAdmins} = adminslice.actions;

export default adminslice.reducer
