import jwtDecode from 'jwt-decode';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken,exp) => {
  if (!accessToken) {
    return false;
  }
  // const decoded = jwtDecode(accessToken);
  const currentTime = Date.now();


  return exp > currentTime;
};

const handleTokenExpired = (exp) => {
  
  if(!isValidToken){
    alert('Token expired');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }

  let expiredTimer;

  exp = localStorage.getItem('validUpto')


  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert('Token expired');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken,validUpto) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('validUpto',validUpto);
    axios.defaults.headers.common.Authorization = accessToken;

    // This function below will handle when token is expired
    handleTokenExpired(validUpto);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export {isValidToken, setSession };
