import axios from '../utils/axios';
import { baseEndpoints } from './config';

const getCountryList = async () => {
    const { data } = await axios.get(`${baseEndpoints.address}/get_country_list`
    )
    return data;
};

const addCountry = async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/add_country`,
        body
    );
    return data;
};


const getStateList = async () => {
    const { data } = await axios.get(`${baseEndpoints.address}/get_state_list`
    )
    return data;
};

const getStateListByCountryId = async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/get_state_list_by_country_id`,body
    )
    return data;
};
const addState = async (body) => {
    const { data } = await axios.post(`${baseEndpoints.address}/add_state`, body
    )
    return data;
};



export const stateService = {
    getCountryList,
    addCountry,
    getStateListByCountryId,
    getStateList,
    addState
}