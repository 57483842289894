import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import PollQuestions from '../pages/dashboard/PollsQuestions';
import PollPublish from '../pages/dashboard/PollsPublish';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'orders',
          children: [
            { element: <Navigate to="/dashboard/orders/orderPlan" replace />, index: true },
            {
              path: 'orderPlan',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <EcommerceOrderList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'customerOrders',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <ConsumerOrderList />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/view',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <ViewCustomerOrder />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <EcommerceOrderCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <EcommerceOrderCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'delivery',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate', 'Delivery Partner']} hasContent>
                  <OrderDeliveryList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'sales',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <OrderSalesList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'adminPurchase',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <AdminPurchaseList />
                </RoleBasedGuard>
              ),
            },
            // { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            {
              path: 'consumers',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin']} hasContent>
                  <ConsumerList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'adminList',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin']} hasContent>
                  <UserList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['Superadmin']} hasContent>
                  <UserCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':name/edit',
              element: (
                <RoleBasedGuard roles={['Superadmin']} hasContent>
                  <UserCreate />
                </RoleBasedGuard>
              ),
            },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'poll',
          children: [
            { element: <Navigate to="/dashboard/poll/pollsQuestions" replace />, index: true },
            {
              path: 'pollsQuestions',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <PollQuestions />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'pollsPublish',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <PollPublish />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/view',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <PollResults />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'feed',
          children: [
            { element: <Navigate to="/dashboard/feed" replace />, index: true },
            {
              path: 'feed',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin']} hasContent>
                  <Feed />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/view',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin']} hasContent>
                  <ViewFeeds />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/product/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <EcommerceProduct />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':name',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <EcommerceProductDetails />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <EcommerceProductCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':name/edit',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <EcommerceProductEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'city',
          children: [
            { element: <Navigate to="/dashboard/city/list" replace />, index: true },
            { path: 'list', element: <CityList /> },
          ],
        },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/dashboard/settings/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <Settings />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'neighbourhood',
          children: [
            { element: <Navigate to="/dashboard/neighbourhood/list" replace />, index: true },
            { path: 'list', element: <Neighbourhood /> },
          ],
        },
        {
          path: 'apartment',
          children: [
            { element: <Navigate to="/dashboard/apartment/list" replace />, index: true },
            { path: 'list', element: <Apartments /> },
          ],
        },
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard roles={['Superadmin', 'Admin', 'Associate']} hasContent>
                  <Category />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '/', element: <Navigate to="/dashboard/app" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceProduct = Loadable(lazy(() => import('../pages/dashboard/Products')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceOrderList = Loadable(lazy(() => import('../pages/dashboard/EcommerceOrderList')));
const ConsumerOrderList = Loadable(lazy(() => import('../pages/dashboard/ConsumerOrderList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceOrderCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceOrderCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const OrderDeliveryList = Loadable(lazy(() => import('../pages/dashboard/OrderDeliveryList')));
const OrderSalesList = Loadable(lazy(() => import('../pages/dashboard/OrderSalesList')));
const AdminPurchaseList = Loadable(lazy(() => import('../pages/dashboard/AdminPurchaseList')));
const ViewCustomerOrder = Loadable(lazy(() => import('../pages/dashboard/ViewCustomerOrder')));

// General
const CityList = Loadable(lazy(() => import('../pages/dashboard/CityList')));
const Settings = Loadable(lazy(() => import('../pages/dashboard/Settings')));
const Neighbourhood = Loadable(lazy(() => import('../pages/dashboard/Neighbourhood')));
const Apartments = Loadable(lazy(() => import('../pages/dashboard/Apartments')));
const Category = Loadable(lazy(() => import('../pages/dashboard/Category')));
const Feed = Loadable(lazy(() => import('../pages/dashboard/Feed')));
const ViewFeeds = Loadable(lazy(() => import('../pages/dashboard/ViewFeeds')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const ConsumerList = Loadable(lazy(() => import('../pages/dashboard/ConsumerList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// POLL
const PollsQuestions = Loadable(lazy(() => import('../pages/dashboard/PollsQuestions')));
const PollsPublish = Loadable(lazy(() => import('../pages/dashboard/PollsPublish')));
const PollResults = Loadable(lazy(() => import('../pages/dashboard/PollResults')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
